<template>
  <v-card class="pa-5" flat>
    <v-overlay :value="!loaded" absolute opacity="0">
      <v-progress-circular color="primary" indeterminate />
    </v-overlay>

    <template v-if="loaded">
      <h3 class="mb-6">
        {{ $t('Add new teammates to your organization') }}
      </h3>

      <v-row>
        <v-col>
          <v-text-field
            v-model="email"
            :disabled="adding"
            :error-messages="error"
            :label="$t('Teammate email')"
            :loading="adding"
            outlined
            @input.native="error = ''"
          />
        </v-col>
        <v-col sm="auto" cols>
          <v-btn
            :disabled="adding"
            :loading="adding"
            :ripple="false"
            color="primary"
            elevation="0"
            rounded
            @click="addTeammate"
          >
            {{ $t('Add teammate') }}
          </v-btn>
        </v-col>
      </v-row>

      <h3 class="mt-12 mb-6">
        {{ $t('Teammates') }}
      </h3>
      <v-row>
        <v-col>
          <p v-if="teammates.length === 0" class="grey--text">
            {{ $t('You have no teammates yet.') }}
          </p>
          <v-list-item-group>
            <v-list-item
              v-for="{account_id, user} in teammates"
              :key="user.pds_id"
              :ripple="false"
            >
              <v-list-item-icon>
                <app-avatar :image="user.avatar" size="32" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ user.first_name }}
                  {{ user.last_name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ user.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-progress-circular
                  v-if="removing === account_id"
                  indeterminate
                />
                <v-btn
                  v-else
                  :ripple="false"
                  icon
                  small
                  @click="removeTeammate(account_id)"
                >
                  <v-icon v-text="icons.remove" />
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-col>
      </v-row>
    </template>
  </v-card>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import {mdiClose} from '@mdi/js'
import AppAvatar from '@/components/AppAvatar'
import error401handler from '@/mixins/error-401-handler'

export default {
  name: 'ProfileTeammates',
  components: {AppAvatar},
  mixins: [error401handler],
  data () {
    return {
      adding: false,
      removing: false,
      email: '',
      error: '',
      icons: {
        remove: mdiClose
      }
    }
  },
  computed: mapState({
    loaded: state => state.loaders.profile.status,
    profile: state => state.profile,
    teammates: state => state.profile.organization.teammates,
  }),
  methods: {
    ...mapMutations({
      setError: 'error/set'
    }),
    ...mapActions('teammatesBusiness', {
      async addTeammate (dispatch) {
        this.adding = true
        this.error = ''
        try {
          await dispatch('tmAdd', this.email)
          this.email = ''
        } catch (e) {
          if (!this.handleError(e, {mode: 'modal'}) &&
            e.response?.status === 422
          ) {
            this.error = e.response.data.message.email.join(' ')
          }
        } finally {
          this.adding = false
        }
      },
      async removeTeammate (dispatch, accountId) {
        this.removing = accountId
        try {
          await dispatch('tmRemove', accountId)
        } catch (e) {
          if (!this.handleError(e, {mode: 'modal'}) &&
            e.response?.status === 422
          ) {
            this.setError({
              mode: 'modal',
              error: e,
              title: this.$t('Teammate remove failed')
            })
          }
        } finally {
          this.removing = false
        }
      },
    }),
  }
}
</script>
